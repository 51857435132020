<template>
  <div v-if="cashierPermission === true">
    <cant-access></cant-access>
  </div>
  <div v-else-if="cashierPermission === false">
    <div v-if="shops">
      <CRow>
        <CCol md="12">
          <CCard class="shadow-sm" v-if="shops.length === 0" md="12" lg="12">
            <CCardBody>
              <CRow>
                <CCol sm="12" lg="12">
                  <img alt="" src="/img/shop.jpg" class="img-fluid" />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>

          <CRow v-else>
            <CCol md="12" v-for="shop in shops" :key="shop.objectId">
              <CCard color="white" class="shadow-sm text-center">
                <CCardBody>
                  <CRow>
                    <CCol md="4" style="border-right: 1px solid #657383">
                      <h3 class="font-weight-bold text-black">
                        {{ shopName }}
                      </h3>
                      <img alt="" class="img-fluid" width="50%" v-bind:src="shop.remoteImagePath"
                        onerror="this.onerror=null;this.src='https://www.img.in.th/images/d6fa08b8f195f3808f7f3bbcb8e43c9f.png';" />
                      <h4 class="text-center text-success font-weight-bold mt-2">
                        {{ shop.branchName }}
                      </h4>
                    </CCol>
                    <CCol md="8">
                      <table style="width: 100%" aria-describedby="">
                        <tr class="text-left">
                          <th scope="col" class="text-black">{{ $t('businessType') }}</th>
                          <td v-if="shop.businessType === 1" class="text-right text-description">
                            <p>{{ $t('businessType1') }}</p>
                          </td>
                          <td v-if="shop.businessType === 2" class="text-right text-description">
                            <p>{{ $t('businessType2') }}</p>
                          </td>
                          <td v-if="shop.businessType === 3" class="text-right text-description">
                            <p>{{ $t('businessType3') }}</p>
                          </td>
                        </tr>

                        <tr class="">
                          <td style="width: 40%" class="font-weight-bold text-left text-black">
                            {{ $t('taxID') }}
                          </td>
                          <td class="text-right text-description">
                            <p>{{ shop.taxId }}</p>
                          </td>
                        </tr>

                        <tr class="">
                          <td class="font-weight-bold text-left text-black">
                            {{ $t('address') }}
                          </td>
                          <td class="text-right text-description">
                            <p>{{ shop.address1 }} {{ shop.address2 }}</p>
                          </td>
                        </tr>

                        <tr class="">
                          <td class="font-weight-bold text-left text-black">
                            {{ $t('tel') }}
                          </td>
                          <td class="text-right text-description">
                            <p>{{ shop.tel }}</p>
                          </td>
                        </tr>

                        <tr class="">
                          <td class="font-weight-bold text-left text-black">
                            {{ $t('open') }}
                          </td>
                          <td class="text-right text-description">
                            <p>{{ shop.open }} - {{ shop.close }}</p>
                          </td>
                        </tr>
                      </table>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </div>
    <div v-else>
      <no-shop></no-shop>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NoShop from '@/containers/NoShop'
import CantAccess from '@/containers/CantAccess'

export default {
  components: { NoShop, CantAccess },
  computed: {
    ...mapGetters(['shops', 'users']),
    shopName() {
      if (this.shops.length !== 0) {
        return this.shops[0].shopName
      }
    },
    cashierPermission() {
      let cashier = localStorage.getItem('x-auth')
      if (cashier !== null) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
